import React from "react";
import Nav from "../Import/Nav";
import Footer from "../Import/Footer";
import './QAOfficier.css';

const QAOfficier = () =>{
    return(
        <>
        <header>
                <h1>Quality Assurance Officer Dashboard</h1>
                <h4>Hello, QAName!</h4>
            </header>
    <Nav/>
    <section class="manage-qa-officers">
        <h2 style={{paddingTop:'30px', fontSize:'30px'}}>Manage QA Officers</h2>

        {/* <!-- QA Officer List --> */}
        <div class="qa-officer-list">
            <table>
                <thead>
                    <tr>
                        <th>QA Officer Name</th>
                        <th>Email</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>John Doe</td>
                        <td>johndoe@example.com</td>
                        <td>
                            <a href="#">Edit</a> |
                            <a href="#">Delete</a>
                        </td>
                    </tr>
                    {/* <!-- Additional QA officer rows here --> */}
                </tbody>
            </table>
        </div>

        {/* <!-- Add New QA Officer --> */}
        <div class="add-qa-officer">
            <h3>Add New QA Officer</h3>
            <form action="#" method="post">
                <div class="form-group">
                    <label for="qaOfficerName">Name:</label>
                    <input type="text" id="qaOfficerName" name="qaOfficerName" required/>
                </div>
                <div class="form-group">
                    <label for="qaOfficerEmail">Email:</label>
                    <input type="email" id="qaOfficerEmail" name="qaOfficerEmail" required/>
                </div>
                <div class="form-group">
                    <input type="submit" value="Add QA Officer"/>
                </div>
            </form>
        </div>
    </section>
    <Footer/>
        
        </>
    )
}

export default QAOfficier;