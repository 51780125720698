import React, { useEffect, useState } from "react";
import Nav from "../Import/Nav";
import Footer from "../Import/Footer";
import './AdminDashboard.css';

const StudentAdmin = () =>{
    const [studentData, setStudentData] = useState([]);

    useEffect(() => {
        // Simulating dynamic data (replace with actual data from your server)
        setTimeout(() => {
            const activeStudents = Math.floor(Math.random() * 500); // Replace with actual data
            const activeTeachers = Math.floor(Math.random() * 100); // Replace with actual data
            const activeCoordinators = Math.floor(Math.random() * 20); // Replace with actual data

            // Simulating a list of students
            const studentList = [
                { id: 1, name: 'Student A', grade: '10th', status: 'Active' },
                { id: 2, name: 'Student B', grade: '11th', status: 'Inactive' },
                { id: 3, name: 'Student C', grade: '12th', status: 'Active' },
            ];

            // Update state with fetched data
            setStudentData(studentList);
        }, 1000); // Simulating a 2-second delay for data fetching
    }, []);

    return(
        <>
            <header>
                <h1>Admin Dashboard</h1>
                <h4>Hello, UserName!</h4>
            </header>
            <Nav />
            <section>
                <div className="student-list" style={{ height: '100vh' }}>
                    <h2>Pending Student List</h2>
                    <table>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Grade</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody id="studentTableBody">
                        {studentData.map((student) => (
                                <tr key={student.id}>
                                    <td>{student.id}</td>
                                    <td>{student.name}</td>
                                    <td>{student.grade}</td>
                                    <td>{student.status}</td>
                                    <td>
                                        <button className="approve-btn" style={{ backgroundColor: 'red', borderRadius: '50px', height: '30px', width: '30px', marginRight: '20px' }}>
                                            <i className="fas fa-check" style={{ color: 'white' }}></i>
                                        </button>
                                        <button className="reject-btn" style={{ backgroundColor: 'green', borderRadius: '50px', height: '30px', width: '30px', marginRight: '20px' }}>
                                            <i className="fas fa-times" style={{ color: 'white' }}></i>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </section>
            <Footer/>
        </>

    )
}

export default StudentAdmin;