import React from "react";
import './Signup.css';
import Header from "../Import/Header";
import Nav from "../Import/Nav";
import Footer from "../Import/Footer";

const Signup = () =>{
    return(
        <>
        <Header/>
        <Nav/>
            <section class="signup-section">
        <div class="form-div">
        <h2 style={{textAlign:"center"}}>Signup for Skillfy</h2>
        <form action="signup_process.php" method="post">
            <div class="form-container">
                <label for="role">Role</label>
                <select id="role" name="role" required>
                    <option value="" disabled selected>Select your role</option>
                    <option value="student">Student</option>
                    <option value="teacher">Teacher</option>
                    <option value="professional">Professional</option>
                </select>
            </div>
            <div class="form-container">
                <label for="name">Name</label>
                <input type="text" id="name" name="name" required placeholder="Enter your name"/>
        
                <label for="address">Address</label>
                <input type="text" id="address" name="address" required placeholder="Enter your address"/>
            </div>
        
            <div class="form-container">
                <label for="contact">Contact Number</label>
                <input type="tel" id="contact" name="contact" required placeholder="Enter your contact number"/>
        
                <label for="location">Location</label>
                <input type="text" id="location" name="location" required placeholder="Enter your location"/>
            </div>
        
            <div class="form-container">
                <label for="degree">Degree</label>
                <input type="text" id="degree" name="degree" required placeholder="Enter your degree"/>
        
                <label for="certification">Certification</label>
                <input type="text" id="certification" name="certification" required placeholder="Enter your certification"/>
            </div>
        
            <div class="form-container">
                <label for="age">Age</label>
                <input type="number" id="age" name="age" required placeholder="Enter your age"/>
        
                <label for="email">Email</label>
                <input type="email" id="email" name="email" required placeholder="Enter your email"/>
            </div>
        
            
        
            <div class="form-container">
                <label for="password">Password</label>
                <input type="password" id="password" name="password" required placeholder="Enter your password"/>
            </div>
        
            <button type="submit" class="signup-button">Signup</button>
        </form>
        <p>Already have an account? <a href="login.html">Login</a></p>
    </div>
    </section>
    <Footer/>
        </>
    )
}

export default Signup;