import React from "react";
import './Footer.css';

const Footer = () =>{
    return(
        <footer>
            <p>&copy; 2024 Skillfy. All rights reserved.</p>
        </footer>
    )
}

export default Footer;