import React from "react";
import Nav from "../Import/Nav";
import Footer from "../Import/Footer";

const ManagePermission = () =>{
    return(
        <>
        <header>
                <h1>Coordinator</h1>
                <h4>Hello, UserName!</h4>
            </header>
    <Nav/>

    <Footer/>
    <section class="manage-permissions">
        <h2>Manage Permissions</h2>

        {/* <!-- Permission List --> */}
        <div class="permission-list">
            <h3>All Permissions</h3>
            <table>
                <thead>
                    <tr>
                        <th>User</th>
                        <th>Permission Level</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Gyane</td>
                        <td>Coordinator</td>
                        <td>
                            <a href="#">Edit</a> |
                            <a href="#">Delete</a>
                        </td>
                    </tr>
                    {/* <!-- Additional permission rows here --> */}
                </tbody>
            </table>
        </div>

        {/* <!-- Add New Permission --> */}
        <div class="add-permission">
            <h3>Add New Permission</h3>
            <form action="#" method="post">
                <div class="form-group">
                    <label for="user">User:</label>
                    <input type="text" id="user" name="user" required/>
                </div>
                <div class="form-group">
                    <label for="permission">Permission Level:</label>
                    <select name="permission" id="permission" required>
                        <option value="coordinator">Coordinator</option>
                        <option value="instructor">Instructor</option>
                        <option value="student">Student</option>
                        {/* <!-- Additional permission levels here --> */}
                    </select>
                </div>
                <div class="form-group">
                    <button className="assignButton">
                        Add Permission
                    </button>
                </div>
            </form>
        </div>
    </section>
        </>
    )
}

export default ManagePermission;