import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router , Routes,Route} from 'react-router-dom';
import Home from './Components/Home/Home';
import About from './Components/Home/About';
import Contact from './Components/Home/Contact';
import Login from './Components/Home/Login';
import Signup from './Components/Home/Signup';
import Dashboard from './Components/Student/Dashboard';
import Courses from './Components/Student/Courses';
import Progress from './Components/Student/Progress';
import Profile from './Components/Student/Profile';
import Logout from './Components/Logout';
import AdminDashboard from './Components/Admin/AdminDashboard';
import StudentAdmin from './Components/Admin/StudentAdmin';
import InstructorAdmin from './Components/Admin/InstructorAdmin';
import AdminQa from './Components/Admin/AdminQa';
import AdminCoordinator from './Components/Admin/AdminCoordinator';
import InstructorDashboard from './Components/Instructor/InstructorDashboard';
import AssignCourse from './Components/Instructor/AssignCourse';
import AssignGrade from './Components/Instructor/AssignGrade';
import Message from './Components/Instructor/Message';
import QAOfficier from './Components/QA/QAOfficier';
import QAPermissions from './Components/QA/QAPermissions';
import Report from './Components/QA/Report';
import QAPerformance from './Components/QA/QAPerformance';
import QAassign from './Components/QA/QAassign';
import Coordinator from './Components/Coordinator/Coordiator';
import HandleCourse from './Components/Coordinator/HandlleCourse';
import ManagePermission from './Components/Coordinator/ManagePermission';
import AssignStudent from './Components/Coordinator/AssignStudent';
import Reviews from './Components/Coordinator/Reviews';

function App() {
  return (
    <>
    <Router>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/about' element={<About/>}/>
        <Route path='/contact' element={<Contact/>}/>
        <Route path='/login' element={<Login/>}/>
        <Route path='/signup' element={<Signup/>}/>
        <Route path='/student/dashboard' element={<Dashboard/>}/>
        <Route path='/student/course' element={<Courses/>}/>
        <Route path='/student/progress' element={<Progress/>}/>
        <Route path='/profile' element={<Profile/>}/>
        <Route path='/logout' element={<Logout/>}/>
        <Route path='/admin/dashboard' element={<AdminDashboard/>}/>
        <Route path='/admin/student' element={<StudentAdmin/>}/>
        <Route path='/admin/instructor' element={<InstructorAdmin/>}/>
        <Route path='/admin/qa' element={<AdminQa/>}/>
        <Route path='/admin/coordinator' element={<AdminCoordinator/>}/>
        <Route path='/assigncourses' element={<AssignCourse/>}/>
        <Route path='/instructor/dashboard' element={<InstructorDashboard/>}/>
        <Route path='/assign-grade' element={<AssignGrade/>}/>
        <Route path='/message' element={<Message/>}/>
        <Route path='/qa/manage' element={<QAOfficier/>}/>
        <Route path='/qa/assignscourses' element={<QAassign/>}/>
        <Route path='/qa/managepermissions' element={<QAPermissions/>}/>
        <Route path='/report' element={<Report/>}/>
        <Route path='/performance' element={<QAPerformance/>}/>
        <Route path='/coordinator/manage-coordinate' element={<Coordinator/>}/>
        <Route path='/handle-course' element={<HandleCourse/>}/>
        <Route path='/manage-permission' element={<ManagePermission/>}/>
        <Route path='/assign-student' element={<AssignStudent/>}/>
        <Route path='/reviews' element={<Reviews/>}/>
      </Routes>
    </Router>
    
    </>
    
  );
}

export default App;
