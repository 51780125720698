import React from "react";
import Nav from "../Import/Nav";
import './QAassign.css';

const QAassign = () =>{
    return(
        <>
        <header>
                <h1>Quality Assurance Officer Dashboard</h1>
                <h4>Hello, QAName!</h4>
            </header>
        <Nav/>
        <section class="assign-course-to-student">
        <h2 style={{fontSize:'30px', textAlign:'center',paddingTop:'20px'}}>Assign Course to Student</h2>

        {/* <!-- Assign Course Form --> */}
        <div class="assign-course-form1">
            <h3 style={{fontSize:'30px', textAlign:'center',translateX: '100px'}}>Assign Course</h3>
            <form action="#" method="post">
                <div class="form-group">
                    <label for="student">Select Student:</label>
                    <select name="student" id="student" required>
                        <option value="student1">Student 1</option>
                        <option value="student2">Student 2</option>
                        <option value="student3">Student 3</option>
                        {/* <!-- Add more options as needed --> */}
                    </select>
                </div>
                <div class="form-group">
                    <label for="course">Select Course:</label>
                    <select name="course" id="course" required>
                        <option value="course1">Course 1</option>
                        <option value="course2">Course 2</option>
                        <option value="course3">Course 3</option>
                        {/* <!-- Add more options as needed --> */}
                    </select>
                </div>
                <div class="form-group">
                    <button className="assignButton">
                        Assign
                    </button>
                </div>
            </form>
        </div>

        {/* <!-- Remove Assigned Course Form --> */}
        <div class="remove-assigned-course-form1">
            <h3 style={{fontSize:'30px', textAlign:'center',translateX: '100px'}}>Remove Assigned Course</h3>
            <form action="#" method="post">
                <div class="form-group">
                    <label for="student">Select Student:</label>
                    <select name="student" id="student" required>
                        <option value="student1">Student 1</option>
                        <option value="student2">Student 2</option>
                        <option value="student3">Student 3</option>
                        {/* <!-- Add more options as needed --> */}
                    </select>
                </div>
                <div class="form-group">
                    <label for="assignedCourse">Assigned Course:</label>
                    <select name="assignedCourse" id="assignedCourse" required>
                        <option value="course1">Course 1</option>
                        <option value="course2">Course 2</option>
                        <option value="course3">Course 3</option>
                        {/* <!-- Add more options as needed --> */}
                    </select>
                </div>
                <div class="form-group">
                    <button className="assignButton">
                        Remove Assign
                    </button>
                </div>
            </form>
        </div>
    </section>

        </>
    )
}

export default QAassign;