import React, { useEffect, useState } from "react";
import Nav from "../Import/Nav";
import Header from "../Import/Header";

const AdminQa = () =>{
    const [coordinateData, setCoordinateData] = useState([]);

    useEffect(() => {
        // Simulating dynamic data (replace with actual data from your server)
        setTimeout(() => {
            const fetchedCoordinateData = [
                { id: 1, name: 'Coordinator A', stream: 'Maths', status: 'Active' },
                { id: 2, name: 'Coordinator B', stream: 'Maths', status: 'Inactive' },
                { id: 3, name: 'Coordinator C', stream: 'Maths', status: 'Active' },
            ];

            // Update the state with fetched data
            setCoordinateData(fetchedCoordinateData);
        }, 1000); // Simulating a 2-second delay for data fetching
    }, []);
    return(
        <>
            <header>
                <h1>Admin Dashboard</h1>
                <h4>Hello, UserName!</h4>
            </header>

            <Nav />
            <section>
                <div className="student-list" style={{ height: '100vh' }}>
                    <h2>Pending Coordinator List</h2>
                    <table>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Stream</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody id="coordinatorTableBody">
                            {coordinateData.map(coordinator => (
                                <tr key={coordinator.id}>
                                    <td>{coordinator.id}</td>
                                    <td>{coordinator.name}</td>
                                    <td>{coordinator.stream}</td>
                                    <td>{coordinator.status}</td>
                                    <td>
                                        <button className="approve-btn" style={{ backgroundColor: 'red', borderRadius: '50px', height: '30px', width: '30px', marginRight: '20px' }}>
                                            <i className="fas fa-check" style={{ color: 'white' }}></i>
                                        </button>
                                        <button className="reject-btn" style={{ backgroundColor: 'green', borderRadius: '50px', height: '30px', width: '30px', marginRight: '20px' }}>
                                            <i className="fas fa-times" style={{ color: 'white' }}></i>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </section>

            <Header/>
        </>
    )
}

export default AdminQa