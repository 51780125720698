import React from "react";
import Footer from "../Import/Footer";
import Nav from "../Import/Nav";

const AssignStudent = () =>{
    return(
        <>
        <header>
                <h1>Coordinator</h1>
                <h4>Hello, UserName!</h4>
            </header>
            <Nav/>
            <section class="assign-student-to-coordinator">
        <h2>Assign Student to Professors</h2>

        {/* <!-- Student List --> */}
        <div class="student-list">
            <h3>All Students</h3>
            <table>
                <thead>
                    <tr>
                        <th>Student Name</th>
                        <th>Coordinator</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Gyane</td>
                        <td>Professor Name</td>
                        <td>
                            <a href="#">Assign Professors</a>
                        </td>
                    </tr>
                    {/* <!-- Additional student rows here --> */}
                </tbody>
            </table>
        </div>
    </section>

            <Footer/>
        
        </>
    )
}

export default AssignStudent;