import React from "react";
import Nav from "../Import/Nav";
import Footer from "../Import/Footer";
import './AssignCourse.css';

const AssignCourse = () =>{
    return(
        <>
            <header>
                <h1>Instructor Dashboard</h1>
                <h4>Hello, InstructorName!</h4>
            </header>

            <Nav />
            <section style={{paddingTop:'50px'}} className="assign-course">
                <h2 style={{textAlign: "center",paddingTop:'30px'}}>Select Course and Student</h2>
                <form action="#" method="post">
                    <div className="form-group">
                        <label htmlFor="course">Select Course:</label>
                        <select name="course" id="course">
                            <option value="course1">Course 1</option>
                            <option value="course2">Course 2</option>
                            <option value="course3">Course 3</option>
                            {/* Add more options as needed */}
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="student">Select Student:</label>
                        <select name="student" id="student">
                            <option value="student1">Student 1</option>
                            <option value="student2">Student 2</option>
                            <option value="student3">Student 3</option>
                            {/* Add more options as needed */}
                        </select>
                    </div>
                    <div className="form-group">
                    <button className='assignButton'>
                            Assign
                        </button>
                    </div>
                </form>
            </section>
            <Footer />
        </>
    )
}

export default AssignCourse;