import React from "react";
import Nav from "../Import/Nav";
import Footer from "../Import/Footer";

const HandleCourse = () =>{
    return(
        <>
        <header>
                <h1>Coordinator</h1>
                <h4>Hello, UserName!</h4>
            </header>
            <Nav/>

<section class="manage-course">
        <h2>Manage Courses</h2>

        {/* <!-- Course List --> */}
        <div class="course-list">
            <h3>All Courses</h3>
            <table>
                <thead>
                    <tr>
                        <th>Course Code</th>
                        <th>Course Name</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>CSCI101</td>
                        <td>Introduction to Computer Science</td>
                        <td>
                            <a href="#">Edit</a> |
                            <a href="#">Delete</a>
                        </td>
                    </tr>
                    {/* <!-- Additional course rows here --> */}
                </tbody>
            </table>
        </div>

        {/* <!-- Add New Course --> */}
        <div class="add-course">
            <h3>Add New Course</h3>
            <form action="#" method="post">
                <div class="form-group">
                    <label for="courseCode">Course Code:</label>
                    <input type="text" id="courseCode" name="courseCode" required/>
                </div>
                <div class="form-group">
                    <label for="courseName">Course Name:</label>
                    <input type="text" id="courseName" name="courseName" required/>
                </div>
                <div class="form-group">
                    <button className="assignButton">
                        Add Course
                    </button>
                </div>
            </form>
        </div>
    </section>
    <Footer/>
        
        </>
    )
}

export default HandleCourse;