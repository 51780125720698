import React from "react";
import './Signup.css';
import Header from "../Import/Header";
import Nav from "../Import/Nav";
import Footer from "../Import/Footer";

const Contact = () =>{
    return(
        <>
        <Header/>
        <Nav/>
            <section class="signup-section">
                <div class="form-div">
                    <h1 style={{textAlign: "center"}}>Contact Us</h1>
                    <p style={{textAlign: "center"}}>Have questions or need assistance? Reach out to us!</p>

                    <form action="process_contact.php" method="post">
                        <div class="form-container">
                        <label for="name">Name</label>
                        <input type="text" id="name" name="name" required/>

                        <label for="email">Email</label>
                        <input type="email" id="email" name="email" required/>

                        <label for="message">Message</label>
                        <textarea id="message" name="message" rows="4" required></textarea>
                        </div>

                        <button type="submit" class="signup-button">Submit</button>
                    </form>

                </div>
            </section>
            <Footer/>
        </>
    )
}

export default Contact;