import React from "react";
import Header from "../Import/Header";
import Nav from "../Import/Nav";

const About = () =>{
    return(
        <>
            <Header/>
            <Nav/>
            <section class="signup-section">
            <div class="form-div">
                <h1>About Us</h1>
                <p>Welcome to Skillfy, your one-stop destination for learning and skill enhancement.</p>

                <h2>Our Mission</h2>
                <p>At Skillfy, we are dedicated to providing high-quality courses and tutorials to help you acquire new skills and advance in your career.</p>

                <h2>Our Team</h2>
                <p>We have a passionate team of educators and professionals committed to creating engaging and effective learning experiences for our users.</p>

                <h2>Why Choose Skillfy?</h2>
                <p>- Diverse range of courses and tutorials</p>
                <p>- Experienced instructors</p>
                <p>- Flexible learning options</p>
                <p>- Career-focused content</p>
            </div>
        </section>
    </>
    )
}

export default About;