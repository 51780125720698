import React from "react";
import Nav from "../Import/Nav";
import Footer from "../Import/Footer";
// import 'bootstrap/dist/css/bootstrap.min.css'
import './Profile.css';

const Profile = () => {
    function openForm() {
        document.getElementById("myForm").style.display = "block";
      }
      
      function closeForm() {
        document.getElementById("myForm").style.display = "none";
      }
      function openForm1() {
        document.getElementById("myForm1").style.display = "block";
      }
      
      function closeForm1() {
        document.getElementById("myForm1").style.display = "none";
      }
      function myFunction() {
        var x = document.getElementById("navbar-right");
        if (x.className === "nav-right") {
          x.className += " responsive";
        } else {
          x.className = "nav-right";
        }
      }
    return(
        <>
            <header>
                <h1>Skillfy</h1>
                <p>We are here to help You!</p>
            </header>

            <Nav/>
            <section class="dashboard">
    {/* <!-- Student Profile --> */}
    <div class="student-profile">
      <div class="container">
        <div class="row">
          <div class="col-lg-4">
            <div class="card shadow-sm">
              <div class="card-header bg-transparent text-center">
                <img class="profile_img" src="images/uni1.jpg" alt="Profile Image"/>
                <h3>User Name</h3>
              </div>
              <div class="card-body">
                <p class="mb-0"><strong class="pr-1">Student ID:</strong>32163004551</p>
                <p class="mb-0"><strong class="pr-1">Email:</strong>gxt9675@mavs.uta.edu</p>
                <p class="mb-0"><strong class="pr-1">Phone:</strong>+1 9423682</p>
                <button class="open-button1" onclick="openForm1()">Edit</button>
              </div>
            </div>
          </div>
          <div class="col-lg-5">
            <div class="card shadow-sm">
              <div class="card-header bg-transparent border-0">
                <h3 class="mb-0">General Information</h3>
              </div>
              <div class="card-body pt-0">
                <table class="table table-bordered">
                  <tr>
                    <th width="3%">year</th>
                    <td width="0.05%">:</td>
                    <td width="10%">Masters</td>
                  </tr>
                  <tr>
                    <th width="3%">Academic Year </th>
                    <td width="0.05%">:</td>
                    <td width="10%">2020</td>
                  </tr>
                  <tr>
                    <th width="3%">Gender</th>
                    <td width="0.05%">:</td>
                    <td width="10%">Male</td>
                  </tr>
                  <tr>
                    <th width="3%">Address</th>
                    <td width="0.05%">:</td>
                    <td width="10%">UTA blvd</td>
                  </tr>
                  <tr>
                    <th width="3%">blood</th>
                    <td width="0.05%">:</td>
                    <td width="10%">B+</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button class="open-button" onclick="openForm()">Chat</button>
  </section>

  <div class="chat-popup" id="myForm">
    <form action="/action_page.php" class="form-container">
        <h1>Chat</h1>

        <label for="msg"><b>Message</b></label>
        <textarea placeholder="Type message.." name="msg" required></textarea>

        <button type="submit" class="btn">Send</button>
        <button type="button" class="btn2" onclick="closeForm()">Close</button>
    </form>
    </div>
    <div class="edit-popup" id="myForm1">
    <form action="/action_page.php" class="form-container1">
        <h1>Edit User</h1>

        <label for="name"><b>User Name</b></label>
        <input type="text" placeholder="Name" name="name" required></input>
        <label for="id"><b>Student ID</b></label>
        <input type="number" placeholder="100*******" name="id" maxlength="10" required></input>
        <label for="email"><b>Email</b></label>
        <input type="email" placeholder="@mavs.uta.edu" name="mail" required></input>
        <label for="phone"><b>Phone No</b></label>
        <input type="number" placeholder="+1 " name="phone" required maxlength="10"></input>
        <label for="address"><b>Address</b></label>
        <input type="textarea" placeholder="Address" name="address" required></input>

        <button type="submit" class="btn">Submit</button>
        <button type="button" class="btn2" onclick="closeForm1()">Close</button>
    </form>
    </div>
    <Footer/>
        </>
    )
}

export default Profile;