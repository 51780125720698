import React from "react";
import Nav from "../Import/Nav";
import Footer from "../Import/Footer";
import './Progress.css';

const Progress = () =>{
    return(
        <>
            <header>
                <h1>Skillfy</h1>
                <p>We are here to help you!</p>
            </header>

            <Nav/>
            <section>
                    <div class="container">
                        <h1>Student Progress</h1>
                        <div class="student">
                            <h2>Gyane</h2>
                            <p>Student ID: 12345</p>
                            <p>GPA: 3.5</p>
                            <p>Enrolled Courses:</p>
                            <ul>
                                <li>Introduction to Computer Science</li>
                                <li>Web Development</li>
                                <li>Data Structures</li>
                            </ul>
                            <p>Completed Courses:</p>
                            <ul>
                                <li>Introduction to Computer Science</li>
                                <li>Web Development</li>
                            </ul>
                        </div>
                    </div>

                </section>

                <Footer />
        </>
    )
}

export default Progress;