import React from "react";
import './Login.css';
import Header from "../Import/Header";
import Footer from "../Import/Footer";
import Nav from "../Import/Nav";

const Login = () =>{
    return(
        <>
        <Header/>
        <Nav/>
            <section class="login-section">
            <div class="form-div">
            <h2 style={{textAlign: "center"}}>Login to Skillfy</h2>
            <form action="login_process.php" method="post">
                <label for="email">Email</label>
                <input type="text" id="email" name="email" required/>

                <label for="password">Password</label>
                <input type="password" id="password" name="password" required/>

                <button type="submit" class="login-button" onclick="redirectToDashoard()">Login</button>
            </form>
            <p style={{textAlign: "center"}}>Don't have an account? <a href="signup.html">Sign up</a></p>
        </div>
        </section>
        <Footer/>
    </>
    )
}

export default Login;