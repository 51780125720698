import React, { useEffect, useState } from "react";
import Nav from "../Import/Nav";
import Footer from "../Import/Footer";

const Coordinator = () =>{
    const [coordinatorData, setCoordinatorData] = useState([]);

    useEffect(() => {
        // Simulating dynamic data fetching (replace with actual data fetching logic)
        const fetchData = async () => {
            const response = await fetchCoordinatorData(); // Assuming fetchCoordinatorData is a function to fetch data
            setCoordinatorData(response);
        };

        fetchData();
    }, []);

    // Simulated function to fetch coordinator data
    const fetchCoordinatorData = () => {
        return new Promise(resolve => {
            setTimeout(() => {
                const coordinateData = [
                    { id: 1, name: 'Coordinator A', stream: 'Maths', status: 'Active' },
                    { id: 2, name: 'Coordinator B', stream: 'Maths', status: 'Inactive' },
                    { id: 3, name: 'Coordinator C', stream: 'Maths', status: 'Active' },
                ];
                resolve(coordinateData);
            }, 1000); // Simulating a 2-second delay for data fetching
        });
    };

    return(

        <>
        <header>
                <h1>Coordinator</h1>
                <h4>Hello, UserName!</h4>
            </header>
            <Nav/>
            <section>
                <div className="student-list" style={{ height: '100vh' }}>
                    <h2>Manage Coordinate</h2>
                    <table>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Grade</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {coordinatorData.map(student => (
                                <tr key={student.id}>
                                    <td>{student.id}</td>
                                    <td>{student.name}</td>
                                    <td>{student.stream}</td>
                                    <td>{student.status}</td>
                                    <td>
                                        <button className="approve-btn" style={{ backgroundColor: 'red', borderRadius: '50px', height: '30px', width: '30px', marginRight: '20px' }}>
                                            <i className="fas fa-check" style={{ color: 'white' }}></i>
                                        </button>
                                        <button className="reject-btn" style={{ backgroundColor: 'green', borderRadius: '50px', height: '30px', width: '30px', marginRight: '20px' }}>
                                            <i className="fas fa-times" style={{ color: 'white' }}></i>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </section>

            <Footer/>
        
        </>
    )
}

export default Coordinator;