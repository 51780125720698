import React, { useEffect, useState } from 'react';
import './AdminDashboard.css'; // Import CSS file for styling
import Nav from '../Import/Nav';
import Footer from '../Import/Footer';

function AdminDashboard() {
    // Define state variables for student, instructor, coordinator, and QA officer data
    const [studentData, setStudentData] = useState([]);
    const [instructorData, setInstructorData] = useState([]);
    const [coordinatorData, setCoordinatorData] = useState([]);
    const [qaData, setQaData] = useState([]);

    useEffect(() => {
        // Simulate fetching data (replace with actual data fetching)
        setTimeout(() => {
            // Simulated data
            const students = [
                { id: 1, name: 'Student A', grade: '10th', status: 'Active' },
                { id: 2, name: 'Student B', grade: '11th', status: 'Inactive' },
                { id: 3, name: 'Student C', grade: '12th', status: 'Active' }
            ];

            const instructors = [
                { id: 1, name: 'Instructor A', stream: 'Maths', status: 'Active' },
                { id: 2, name: 'Instructor B', stream: 'Physics', status: 'Inactive' },
                { id: 3, name: 'Instructor C', stream: 'Chemistry', status: 'Active' }
            ];

            const coordinators = [
                { id: 1, name: 'Coordinator A', stream: 'Maths', status: 'Active' },
                { id: 2, name: 'Coordinator B', stream: 'Physics', status: 'Inactive' },
                { id: 3, name: 'Coordinator C', stream: 'Chemistry', status: 'Active' }
            ];

            const qaOfficers = [
                { id: 1, name: 'QA Officer A', stream: 'Maths', status: 'Active' },
                { id: 2, name: 'QA Officer B', stream: 'Physics', status: 'Inactive' },
                { id: 3, name: 'QA Officer C', stream: 'Chemistry', status: 'Active' }
            ];

            // Update state with fetched data
            setStudentData(students);
            setInstructorData(instructors);
            setCoordinatorData(coordinators);
            setQaData(qaOfficers);
        }, 5); // Simulating a delay for data fetching
    }, []);

    return (
        <div>
            <header>
                <h1>Admin Dashboard</h1>
                <h4>Hello, UserName!</h4>
            </header>
            <Nav />
            <section className='section1'>
    <div className="main-div">
        {/* Content for displaying total students, instructors, coordinators, and QA officers */}
        <div className="container1">
            <h3><i className='fas'>&#xf501;</i>Total Student<br />
                <h2>{studentData.length}</h2></h3><br />
        </div>
        <div className="container1">
            <h3><i className="fas fa-chalkboard-teacher"></i>Total Instructor<br />
                <h2>{instructorData.length}</h2></h3><br />
        </div>
        <div className="container1">
            <h3><i className="fa-solid fa-user"></i>Total Coordinator<br />
                <h2>{coordinatorData.length}</h2></h3><br />
        </div>
        <div className="container1">
            <h3><i className="fa-solid fa-user-tie"></i>Total QA Officer<br />
                <h2>{qaData.length}</h2></h3><br />
        </div>
    </div>
    <div className="student-list">
        <h2>Pending Student List</h2>
        <table>
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Grade</th>
                    <th>Status</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                {/* Map and render the student data */}
                {studentData.map(student => (
                    <tr key={student.id}>
                        <td>{student.id}</td>
                        <td>{student.name}</td>
                        <td>{student.grade}</td>
                        <td>{student.status}</td>
                        <td>
                            <button className="approve-btn" style={{ backgroundColor: 'red', borderRadius: '50px', height: '30px', width: '30px', marginRight: '20px' }}>
                                <i className="fas fa-check" style={{ color: 'white' }}></i>
                            </button>
                            <button className="reject-btn" style={{ backgroundColor: 'green', borderRadius: '50px', height: '30px', width: '30px', marginRight: '20px' }}>
                                <i className="fas fa-times" style={{ color: 'white' }}></i>
                            </button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
    <div className="student-list">
        <h2>Pending Instructor List</h2>
        <table>
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Stream</th>
                    <th>Status</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                {/* Map and render the student data */}
                {instructorData.map(student => (
                    <tr key={student.id}>
                        <td>{student.id}</td>
                        <td>{student.name}</td>
                        <td>{student.stream}</td>
                        <td>{student.status}</td>
                        {/* <td>{student.}</td> */}
                        <td>
                            <button className="approve-btn" style={{ backgroundColor: 'red', borderRadius: '50px', height: '30px', width: '30px', marginRight: '20px' }}>
                                <i className="fas fa-check" style={{ color: 'white' }}></i>
                            </button>
                            <button className="reject-btn" style={{ backgroundColor: 'green', borderRadius: '50px', height: '30px', width: '30px', marginRight: '20px' }}>
                                <i className="fas fa-times" style={{ color: 'white' }}></i>
                            </button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
    </section>
            <Footer />
             
        </div>
    );
}

export default AdminDashboard;
