import React from "react";
import Nav from "../Import/Nav";
import Footer from "../Import/Footer";
import './Report.css';

const Report = () =>{
    return(
        <>
           <header>
                <h1>Quality Assurance Officer Dashboard</h1>
                <h4>Hello, QAName!</h4>
            </header>
            <Nav/>
            <section class="report">
        <h2 style={{textAlign: 'center',paddingTop: '20px'}}>Generate Report</h2>

        {/* <!-- Report Options --> */}
        <div class="report-options">
            <form action="#" method="post">
                <div class="form-group">
                    <label for="reportType">Report Type:</label>
                    <select name="reportType" id="reportType" required>
                        <option value="performance">Performance</option>
                        <option value="attendance">Attendance</option>
                        {/* <!-- Add more report types as needed --> */}
                    </select>
                </div>
                <div class="form-group">
                    <label for="startDate">Start Date:</label>
                    <input type="date" id="startDate" name="startDate" required/>
                </div>
                <div class="form-group">
                    <label for="endDate">End Date:</label>
                    <input type="date" id="endDate" name="endDate" required/>
                </div>
                <div class="form-group">
                    <button className="assignButton">
                        Generate Report
                    </button>
                </div>
            </form>
        </div>

        {/* <!-- Generated Report --> */}
        <div class="generated-report">
            <h3 style={{textAlign: "center"}}>Generated Report</h3>
            {/* <!-- Display generated report here --> */}
        </div>
    </section>
    <Footer/>
        </>
    )
}

export default Report;