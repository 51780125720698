import React, { useState } from "react";
import Nav from "../Import/Nav";
import Footer from "../Import/Footer";
import './QAPerformance.css';

const QAPerformance = () =>{
    const [selectedStudent, setSelectedStudent] = useState('student1');
    const [performanceData, setPerformanceData] = useState(getPerformanceDataFromDatabase(selectedStudent));

    function handleStudentChange(event) {
        const selectedValue = event.target.value;
        setSelectedStudent(selectedValue);
        setPerformanceData(getPerformanceDataFromDatabase(selectedValue));
    }
    return(
        <>
            <header>
                <h1>Quality Assurance Officer Dashboard</h1>
                <h4>Hello, QAName!</h4>
            </header>
            <Nav/>
            <section className="student-selection">
                <h2>Select a Student:</h2>
                <select id="studentSelect" value={selectedStudent} onChange={handleStudentChange}>
                    <option value="student1">Student 1</option>
                    <option value="student2">Student 2</option>
                    {/* Add more options for students */}
                </select>
            </section>
            <section className="performance" id="performanceSection">
                <h2>Performance Overview</h2>
                <div className="performance-metrics">
                    {Object.entries(performanceData).map(([metric, value]) => (
                        <div className="metric" key={metric}>
                            <h3>{metric.replace(/([A-Z])/g, ' $1').trim()}</h3>
                            <p>{value}</p>
                        </div>
                    ))}
                </div>
            </section>
            <Footer/>
        
        </>
    )
}

function getPerformanceDataFromDatabase(studentId) {
    if (studentId === "student1") {
        return {
            courseCompletionRate: "80%",
            averageGrade: "B+",
            attendanceRate: "85%"
            // Add more performance metrics as needed
        };
    } else if (studentId === "student2") {
        return {
            courseCompletionRate: "70%",
            averageGrade: "A-",
            attendanceRate: "90%"
            // Add more performance metrics as needed
        };
    }
    // Add more conditions for additional students if needed
}

export default QAPerformance;