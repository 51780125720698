import React from "react";
import Nav from "../Import/Nav";
import Footer from "../Import/Footer";
import './Courses.css';

const Courses = () =>{
    return(
        <>
            <header>
                <h1>Skillfy</h1>
                <p>Welcome, [Student Name]!</p>
            </header>

            <Nav />

            <section>
                <div class="container">
                    <h1 style={{fontSize: '30px', marginLeft: '7px'}}>Courses</h1>
                    <div class="course">
                        <h2>Course Name: Introduction to Computer Science</h2>
                        <p>Course Code: CS101</p>
                        <button class="btn-assessment">Assessment</button>
                        <button class="btn-assignment">Assignment</button>
                    </div>
                    <div class="course">
                        <h2>Course Name: Web Development</h2>
                        <p>Course Code: WD201</p>
                        <button class="btn-assessment">Assessment</button>
                        <button class="btn-assignment">Assignment</button>
                    </div>
                    <div class="course">
                        <h2>Course Name: Data Structures</h2>
                        <p>Course Code: DS301</p>
                        <button class="btn-assessment">Assessment</button>
                        <button class="btn-assignment">Assignment</button>
                    </div>
                </div>

                
            </section>
            <Footer/>
        </>
    )
}

export default Courses;