import React from "react";
import './Home.css'
import { Link } from "react-router-dom";
import Header from "../Import/Header";
import Nav from "../Import/Nav";
import Footer from "../Import/Footer";

const Home = () =>{

    function redirectToLoginPage() {
            
        window.location.href = 'login.html'; 
    }

    function redirectToSignupPage() {
       
        window.location.href = 'signup.html'; 
    }
    return(
        <>
        <Header/>
        <Nav />

        
        <section>
            <div class="Detail_div">
                <h1>Welcome to Skillfy</h1>
                <p>Explore a variety of courses and tutorials<br/>to enhance your knowledge and skills.Enhance Your Knowledge And get Placed</p>
                <p>Start your Dream journey today!</p>
                <div class="button-container">
                    <Link to="/login">
                        <button class="login-button" onclick="redirectToLoginPage()"
                        >Login</button>
                    </Link>
                    <Link to="/signup">
                        <button class="signup-button" onclick="redirectToSignUpPage()">Signup</button>
                    </Link>
                </div>
            </div>
        </section>

        <Footer/>

        </>
    )
}

export default Home;