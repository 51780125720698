import React from "react";
import Footer from "../Import/Footer";
import './Dashboard.css'
import Nav from "../Import/Nav";

const Dashboard = () =>{
    return(
        <>
            <header>
                <h1>Skillfy</h1>
                <p>Welcome, [Student Name]!</p>
            </header>
            <Nav/>

            <section>
                <div class="dashboard-container">
                    <h2>My Dashboard</h2>

                    <div class="quick-links">
                        <a href="/student/course">Explore Courses</a>
                        <a href="/student/progress">View Progress</a>
                        <a href="/student/profile">Update Profile</a>
                    </div>

                    <div class="course-status">
                        <h3 style={{color: "black"}}>Enrolled Courses</h3>
                        <ul>
                            <li><a href="course-details.html">Course Title 1</a> - In Progress</li>
                            <li><a href="course-details.html">Course Title 2</a> - Not Started</li>
                            {/* <!-- Add more enrolled courses as needed --> */}
                        </ul>
                    </div>

                    <div class="completed-courses">
                        <h3 style={{color: "black"}}>Completed Courses</h3>
                        <ul>
                            <li><a href="course-details.html">Course Title 3</a></li>
                            {/* <!-- Add more completed courses as needed --> */}
                        </ul>
                    </div>
                </div>
            </section>

            <Footer/>
        </>
    )
}

export default Dashboard;