import React from "react";
import Nav from "../Import/Nav";
import Footer from "../Import/Footer";
import './InstructorDashboard.css';

const InstructorDashboard = () =>{
    return(
        <>
            <header>
                <h1>Instructor Dashboard</h1>
                <h4>Hello, InstructorName!</h4>
            </header>
            <Nav />
            <section className="section1">
                <div className="main-div1">
                    <div className="container1">
                        <h3><i style={{ fontSize: '60px', color: 'blue', marginRight: '20px' }} className="fas">&#xf19c;</i>Total Courses<br />
                            <h2>10</h2>
                        </h3>
                    </div>
                    <div className="container1">
                        <h3><i style={{ fontSize: '60px', color: 'blue', marginRight: '20px' }} className="fas fa-clipboard-check"></i>Grades Submitted<br />
                            <h2>50</h2>
                        </h3>
                    </div>
                    <div className="container1">
                        <h3><i style={{ fontSize: '60px', color: 'blue', marginRight: '20px' }} className="fas fa-envelope"></i>Unread Messages<br />
                            <h2>3</h2>
                        </h3>
                    </div>
                </div>

                <div className="course-list">
                    <h2>Your Courses</h2>
                    <table>
                        <thead>
                            <tr>
                                <th>Course Code</th>
                                <th>Course Name</th>
                                <th>Students Enrolled</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>CSCI101</td>
                                <td>Introduction to Computer Science</td>
                                <td>30</td>
                                <td><a href="#">View Details</a></td>
                            </tr>
                            {/* Additional course rows here */}
                        </tbody>
                    </table>
                </div>

                <div className="grades">
                    <h2>Your Grades</h2>
                    <table>
                        <thead>
                            <tr>
                                <th>Course</th>
                                <th>Student</th>
                                <th>Grade</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>CSCI101</td>
                                <td>John Doe</td>
                                <td>A</td>
                                <td>Approved</td>
                                <td><a href="#">Edit Grade</a></td>
                            </tr>
                            {/* Additional grade rows here */}
                        </tbody>
                    </table>
                </div>

                <div className="messages">
                    <h2>Messages</h2>
                    <ul>
                        <li><strong>From:</strong> Admin</li>
                        <li><strong>Subject:</strong> Course Announcement</li>
                        <li><strong>Date:</strong> Feb 27, 2024</li>
                        <li><a href="#">Read Message</a></li>
                    </ul>
                    {/* Additional message items here */}
                </div>
            </section>
            <Footer/>
        </>
    )
}

export default InstructorDashboard;