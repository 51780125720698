import React from 'react';
import Nav from '../Import/Nav';
import './Message.css';
import Footer from '../Import/Footer';

function Message() {
    return (
        <div>
            <header>
                <h1>Instructor Dashboard</h1>
                <h4>Hello, InstructorName!</h4>
            </header>
            <Nav/>
            <section className="received-messages">
                <h2 style={{paddingTop:'20px',textAlign:'center'}}>Received Messages</h2>
                <div className="message-list">
                    <div className="message">
                        <div className="message-header">
                            <span className="sender-name">Gyane</span>
                            <span className="message-date">Feb 27, 2024</span>
                        </div>
                        <div className="message-content">
                            <p>Hii Team, I am writing....</p>
                        </div>
                    </div>
                    {/* Additional messages here */}
                </div>
            </section>
            <Footer/>
        </div>
        
    );
}

export default Message;
