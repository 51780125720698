import React from 'react';
import Nav from '../Import/Nav';
import Footer from '../Import/Footer';
import './AssignGrade.css';

const AssignGrade = () => {
    return (
        <div>
            <header>
                <h1>Instructor Dashboard</h1>
                <h4>Hello, InstructorName!</h4>
            </header>
            <Nav/>
            <section className="assign-grade">
                <h2 style={{textAlign: 'center',
            fontSize:'40px',paddingTop:'30px'}}>Assign Grade and Marks</h2>
                <form action="#" method="post">
                    <div className="form-group">
                        <label htmlFor="student">Select Student:</label>
                        <select name="student" id="student">
                            <option value="student1">Student 1</option>
                            <option value="student2">Student 2</option>
                            <option value="student3">Student 3</option>
                            {/* Add more options as needed */}
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="subject">Select Subject:</label>
                        <select name="subject" id="subject">
                            <option value="subject1">Subject 1</option>
                            <option value="subject2">Subject 2</option>
                            <option value="subject3">Subject 3</option>
                            {/* Add more options as needed */}
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="grade">Grade:</label>
                        <input type="text" id="grade" name="grade" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="marks">Marks:</label>
                        <input type="text" id="marks" name="marks" />
                    </div>
                    <div className="form-group">
                        <button className='assignButton'>
                            Assign
                        </button>
                    </div>
                </form>
            </section>
            <Footer/>
        </div>
    );
}

export default AssignGrade;
