import React from "react";
import Footer from "../Import/Footer";
import Nav from "../Import/Nav";

const Reviews = () =>{
    return(
        <>
        <header>
                <h1>Coordinator</h1>
                <h4>Hello, UserName!</h4>
            </header>
            <Nav/>
            <section class="reviews">
        <h2>Reviews</h2>

        {/* <!-- Reviews List --> */}
        <div class="review-list">
            <div class="review">
                <div class="review-info">
                    <h3>Student Name</h3>
                    <span>Course Name</span>
                    <p>Date: <time datetime="2024-02-28">Feb 28, 2024</time></p>
                </div>
                <div class="rating">
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star-half-alt"></i>
                    <i class="far fa-star"></i>
                </div>
                <div class="comment">
                    <p>You have Providing the rights for the Student and Professors that Can
                    help the each of us.</p>
                </div>
            </div>
            {/* <!-- Additional review items here --> */}
        </div>
    </section>

            <Footer/>
        
        </>
    )
}

export default Reviews;