import React from "react";

const Logout = () =>{
    return(
        <div>
            Logout
        </div>
    )
}

export default Logout;