import React from "react";
import './Header.css';

const Header = () =>{
    return(
        <header>
            <h1>Skillfy</h1>
            <p>We are here to help you!</p>
        </header>
    )
}

export default Header;