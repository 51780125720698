import React from "react";
import './Nav.css';
import { Link } from "react-router-dom";

const Nav = () =>{
    const role = 'coordinator';
    return(
        <nav>
           {role === 'undefined' && (
                <>
                    <Link to="/">Home</Link>
                    <Link to="/about">About</Link>
                    <Link to="/contact">Contact</Link>
                    <Link to="/login">Login</Link>
                    <Link to="/signup">Signup</Link>
                </>
            )}
            {role === 'student' && (
                <>
                    <Link to="/student/dashboard">Dashboard</Link>
                    <Link to="/student/course">My Courses</Link>
                    <Link to="/student/progress">Progress</Link>
                    <Link to="/profile">Profile</Link>
                    <Link to="/logout">Logout</Link>
                </>
            )}
            {role === 'admin' && (
                <>
                    <Link to="/admin/dashboard">Dashboard</Link>
                    <Link to="/admin/student">Students</Link>
                    <Link to="/admin/instructor">Instructors</Link>
                    <Link to="/admin/qa">QA-Officer</Link>
                    <Link to="/admin/coordinator">Coordinator</Link>
                    <Link to="/profile">Profile</Link>
                </>
            )

            }
            {
                role === 'instructor' && (
                    <>
                        <Link to="/instructor/dashboard">Dashboard</Link>
                        <Link to="/assigncourses">Courses</Link>
                        <Link to="/assign-grade">Grades</Link>
                        <Link to="/message">Messages</Link>
                        <Link to="/profile">Profile</Link>
                    </>
                )
            }
            {
                role === 'qa' && (
                    <>
                        <Link to="/qa/manage">QA - Officier</Link>
                        <Link to="/qa/assignscourses">Assign Courses</Link>
                        <Link to="/qa/managepermissions">Manage Permissions</Link>
                        <Link to="/report">Report</Link>
                        <Link to="/performance">Performance</Link>
                        <Link to="/profile">Profile</Link>
                    </>
                )
            }
            {
                role=== 'coordinator' && (
                    <>
                        <Link to="/coordinator/manage-coordinate">Coordinator</Link>
                        <Link to="/handle-course">Course</Link>
                        <Link to="/manage-permission">Permissions</Link>
                        <Link to="/assign-student">Student</Link>
                        <Link to="/reviews">Reviews</Link>
                        <Link to="/profile">Profile</Link>
                    </>
                )
            }
            
        </nav>
    )
}

export default Nav;